import React, { useState, useContext } from "react";

import translations from "../../config/translations";
import LanguageContext from "../../contexts/Language";

import styled from "styled-components";
import { KeyboardArrowRight } from "@styled-icons/material";
import { BREAKPOINTS, MAIN_COLOR } from "../../constants";
import isMobile from "../../hooks/is-mobile";

import { Menu, ActivityIndicator, NavBar } from "antd-mobile";

const mainPadding = "35px";

const FilterMenu = styled.ul`
  margin: -10px 0;
  padding: 0;
  list-style: none;
  padding: ${mainPadding};

  font-size: 14px;

  li {
    margin-bottom: 25px;
  }
`;

const MobileHeader = styled.div`
  width: 100%;
  height: 40px;
  background-color: #333333;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  border-radius: 5px;
  text-transform: uppercase;
  margin-bottom: 10px;

  ${(props) =>
    props.isOpen &&
    `
    background-color: #bb4848;
  `}// @media(max - width: ${BREAKPOINTS.MD}) {
  //   display: flex;
  //   justify - content: center;
  //   text - align: center;
  //   flex - wrap: wrap;
  // }
`;

const FilterMenuItem = styled.button`
  width: 100%;
  margin: 0;
  font-size: 14px;
  background: none;
  display: block;
  border: 0;
  outline: none;
  text-align: left;
  font-weight: ${(props) => (props.isActive ? 600 : 400)};
  padding: ${(props) => (props.separate ? "20px 0 20px" : "10px 0 10px")};
  height: 45px;
  position: relative;
  padding-left: 25px;

  &:hover {
    color: #1890ff;
    cursor: pointer;
  }
`;

const Arrow = styled(KeyboardArrowRight)`
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
`;

const BackButton = styled(FilterMenuItem)`
  height: 45px;
  color: #c5c5c5;
  padding-left: 5px;

  &:hover {
    color: #1890ff;
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  }
`;

export default function ProductFilter({
  categoryHasChildren,
  onCategoryClick,
  category,
  showAllCategory,
  visibleCategories,
  categorySlug,
}) {
  const lang = useContext(LanguageContext);
  const [displayMenu, setDisplayMenu] = useState(false);
  const isMobileReso = isMobile();
  const backButton =
    (
      category?.slug && <BackButton onClick={() => onCategoryClick(category.parentSlug || '')}>
        {translations["Back"][lang]} /
      </BackButton>
    )

  const parentItem = showAllCategory ? (
    <FilterMenuItem
      separate
      onClick={() => onCategoryClick(showAllCategory.slug)}
      isActive={categorySlug === showAllCategory.slug}
      key={showAllCategory.slug}
    >
      <Arrow />
      <span dangerouslySetInnerHTML={{ __html: showAllCategory.name }}></span>
    </FilterMenuItem>
  ) : (
    ""
  );

  const clickedCategory = (theCategorySlug) => {
    onCategoryClick(theCategorySlug);
    setDisplayMenu(false);
  };

  return (
    <FilterMenu>
      {isMobileReso ? (
        <MobileHeader
          isOpen={displayMenu}
          onClick={() => setDisplayMenu(!displayMenu)}
        >
          {displayMenu
            ? translations["Close menu"][lang]
            : category?.name || translations["All categories"][lang]}
        </MobileHeader>
      ) : (
        ""
      )}

      {!isMobileReso || displayMenu ? (
        <>
          {backButton}

          {parentItem}

          {visibleCategories.map((categoryItem) => (
            <FilterMenuItem
              onClick={() => clickedCategory(categoryItem.slug)}
              isActive={categorySlug === categoryItem.slug}
              key={categoryItem.slug}
            >
              <Arrow />
              <span
                dangerouslySetInnerHTML={{ __html: categoryItem.name }}
              ></span>
            </FilterMenuItem>
          ))}
        </>
      ) : (
        ""
      )}
    </FilterMenu>
  );
}
