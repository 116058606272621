import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useRouter } from "next/router";

import FilterMenu from "./filter-menu";
import ProductsBlock from "./products-block";

import { MAX_BLOCK_WIDTH, BREAKPOINTS } from "../../constants";

import {
  getActiveCategoryObject,
  getVisibleCategories,
  getShowAllCategory,
  getFlatCategories,
} from "./utils";

const ProductFilterWrapper = styled.div`
  position: relative;
  max-width: ${MAX_BLOCK_WIDTH};
  width: auto;
  padding: 0px;
  margin: 0 auto;
  height: auto;
  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr 4fr;

  @media (max-width: ${BREAKPOINTS.MD}) {
    grid-template-columns: 1fr;
  }

  ${(props) =>
    props.hideMobile &&
    `
    @media(max-width: ${BREAKPOINTS.MD}) {
      display: none;   
    }
  `}
`;

export default function ProductFilter(props) {
  const router = useRouter();
  const [flatCategories, setFlatCategories] = useState(
    getFlatCategories(props.categories)
  );

  const [categorySlug, setCategorySlug] = useState(
    router.query.category || null
  );

  const onCategoryClick = (categorySlug) => {
    setCategorySlug(categorySlug);

    if (typeof props.onCategoryClick === "function") {
      props.onCategoryClick(categorySlug);
    }
  };

  useEffect(() => {
    setFlatCategories(getFlatCategories(props.categories));
  }, [props.categories]);

  useEffect(() => {
    setCategorySlug(router.query.category, 10);
  }, [router.query.category]);

  const category = flatCategories.find((c) => c.slug === categorySlug);
  const categoryHasChildren = !!category?.children?.length;
  const visibleCategories = flatCategories.filter((c) => {
    if (category && category.parentSlug === null && !category?.children?.length) {
      return false
    }

    if (!category && c.parentSlug === null) {
      return true;
    }

    if (!category?.children?.length) {
      return c.parentSlug === category?.parentSlug;
    }

    if (category?.children?.length) {
      const children = (category?.children?.length && category.children) || [];
      return children.includes(c.slug);
    }

    return true;
  });

  let showAllCatSlug = category?.parentSlug || category?.slug;

  if (category?.children?.length && category?.parentSlug) {
    showAllCatSlug = category?.slug;
  }

  const showAllCategory = flatCategories.find((c) => c.slug === showAllCatSlug);

  return (
    <ProductFilterWrapper hideMobile={props.hideMobile}>
      <FilterMenu
        categoryHasChildren={categoryHasChildren}
        onCategoryClick={onCategoryClick}
        category={category}
        showAllCategory={showAllCategory}
        visibleCategories={visibleCategories}
        categorySlug={categorySlug}
      />
      <ProductsBlock
        products={props.products}
        category={{...category, ...(props.activeCategoryData || {})}}
        onPaginationChange={props.onPaginationChange}
        totalCount={props.totalCount}
        defaultPageNr={props.defaultPageNr || 1}
        simple={props.simple}
        moreLink={props.moreLink}
      />{" "}
    </ProductFilterWrapper>
  );
}
