import React, { useRef, useState, useEffect, useContext } from "react";
import styled, { css } from "styled-components";
import Link from "next/link";
import { Pagination, Spin } from "antd";

import translations from "../../config/translations";
import LanguageContext from "../../contexts/Language";

import {
  MAIN_PADDING,
  MAIN_PADDING_2,
  MAIN_COLOR,
  BREAKPOINTS,
} from "../../constants";

import ProductCard from "./product-card";
import RoundedButton from "../rounded-button";
import CustomLink from '../custom-link'


const ProductsWrapper = styled.div`
  background-color: #f8f9ff;
  padding: ${MAIN_PADDING};
  min-height: calc(100vh - 200px);

	&::after {
    content: '';
		position: absolute;
		height: 100%;
		width: 100vw;
		top: 0;
		left: calc(100%);
		background-color: #f8f9ff;
    }
  }

  @media (max-width: ${BREAKPOINTS.MD}) {
    padding: ${MAIN_PADDING / 3};
  }
`;

const ProductsBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: ${MAIN_PADDING};
  grid-row-gap: ${MAIN_PADDING};
  grid-auto-rows: min-content;

  @media (max-width: ${BREAKPOINTS.MD}) {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 10px);

    & > * {
      width: calc(50% - 20px);
      margin-bottom: 10px;
    }
  }
`;

const ProductsBlockTitle = styled.span`
  margin-bottom: ${MAIN_PADDING};
  display: block;
  font-size: 18px;
  font-weight: 600;
`;

const ProductsBlockDescription = styled.p`
  margin-bottom: ${MAIN_PADDING_2};
  display: block;
  font-size: 14px;
  max-width: 75%;
`;

const MoreButtonWrapper = styled.div`
  width: 100%;
  margin-top: ${MAIN_PADDING};
  display: flex;
  justify-content: center;
`;

const PaginationWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
`;

export default function ProductFilter({
  products,
  category,
  onPaginationChange,
  totalCount,
  defaultPageNr,
  simple,
  moreLink,
}) {
  const myRef = useRef(null);
  const lang = useContext(LanguageContext);
  const [currentPage, setCurrentPage] = useState(parseInt(defaultPageNr) || 1);
  const [pageSize, setPageSize] = useState(24);

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const handlePaginationClick = (page, currentPageSize) => {
    console.log('page', {
      page, currentPageSize
    })
    onPaginationChange(page, currentPageSize);
    scrollToRef(myRef);
    setCurrentPage(page);
  };
  const setNewPageSize = (current, newSize) => {
    setPageSize(newSize);
    onPaginationChange(1, newSize);
  };

  return (
    <ProductsWrapper ref={myRef}>
      {!simple && (
        <>
          <ProductsBlockTitle
            dangerouslySetInnerHTML={{
              __html: category?.name || translations["Popular products"][lang],
            }}
          />
          {category?.description && (
            <ProductsBlockDescription>
              {category.description}
            </ProductsBlockDescription>
          )}
        </>
      )}

      <ProductsBlock>
        {products && products.length
          ? products.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))
          : translations["No products in this category"][lang]}
      </ProductsBlock>

      {simple && (
        <MoreButtonWrapper>
          <CustomLink
            href={{
              pathname: moreLink && moreLink.href,
              query: { ...(moreLink && moreLink.params) },
            }}
            as={moreLink && moreLink.href.replace(`[locale]`, lang)}
            passHref
          >
            <a>
              <RoundedButton>{translations["See more"][lang]}</RoundedButton>
            </a>
          </CustomLink>
        </MoreButtonWrapper>
      )}

      {!simple && (
        <PaginationWrapper>
          <Pagination
            defaultCurrent={currentPage}
            total={parseInt(totalCount, 10)}
            pageSize={pageSize}
            onChange={(page, pageSize) => handlePaginationClick(page, pageSize)}
            onShowSizeChange={setNewPageSize}
            current={currentPage}
          />
        </PaginationWrapper>
      )}
    </ProductsWrapper>
  );
}
